<template>
<div>
  <b-container fluid class="bg-white mt-4">
    <b-row class="mb-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Controls Matrix</h1>
        <p>Visualise controls coverage across various dimensions, use filtering and searching to narrow down the population.
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <div class="mb-4" style="display: flex; height: 30px;">
          <filter-interface class="mr-4" namespace="controls" :rows="rows" v-model="rowsFiltered" :staticDataProperties="staticDataProperties" :includeTaxonomy="false"></filter-interface>
          <search-interface namespace="controls" :rows="rowsFiltered" v-model="rowsSearched"></search-interface>
        </div>
        <div>
          <matrix
            :rows="rowsSearched"
            :dictionaries="dictionaries"
          ></matrix>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import FilterInterface from '@/components/FilterInterfaceV6.vue'
import Matrix from '@/components/Matrix.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'

const staticDataProperties = [
  { name: 'areas', property: 'areas', graph: 'areas' },
  { name: 'risks', property: 'risks', graph: 'controlrisks' },
  { name: 'status', property: 'status', graph: 'controlstatus' },
  { name: 'themes', property: 'themes', graph: 'controlthemes' },
  { name: 'projects', property: 'projects', graph: 'projects' }
]

export default {
  name: 'ControlsMatrix',
  components: {
    FilterInterface,
    Matrix,
    SearchInterface
  },
  computed: {
    rows: function () {
      return this.$store.state.controls
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rowsSearched.slice(start, end)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      dictionaries: [],
      loading: true,
      rowsFiltered: [],
      rowsSearched: [],
      staticDataProperties: [
        { name: 'Control Status', property: 'controlstatus', tree: true },
        { name: 'Control Themes', property: 'controlthemes', graph: 'controlthemes' },
        { name: 'Control Risks', property: 'controlrisks', graph: 'controlrisks' },
        { name: 'Control Areas', property: 'areas', graph: 'areas' }
      ]
    }
  },
  created: async function () {
    document.title = "KPMG Controls Matrix"
    try {
      const staticData = await this.$Amplify.API.get('cosmos', '/controls/staticdata')
      console.log(this.staticData)
      _.each(staticDataProperties, property => {
        const staticRow = {
          name: property.name,
          graph: property.graph,
          data: staticData[property.property].map(x => x.name)
        }
        this.dictionaries.push(staticRow)
      })
      this.loading = false
    } catch (e) {
      this.$logger.warn('saved ERROR: ', e)
    }
  }
}
</script>

<style>
</style>
